import React, { useState } from 'react'
import SEO from '../components/seo'
import styled from '@emotion/styled'
import HomePage from './homePage'

const IndexPage = () => {
  return (
    <>
      <App>
        <SEO title="Home" canonical="" />
        <HomePage/>
      </App>
    </>
  )
}

const App = styled.div`
  height:100%;
  width: 100%;
`

export default IndexPage
